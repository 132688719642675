<template>
  <b-card class="p-2">
    <b-row>
      <b-col
        class="mb-4 d-flex justify-content-center align-items-center"
        cols="12"
      >
        <feather-icon
          icon="ShoppingBagIcon"
          size="25"
          class="mr-2"
        />
        <h1>Ultima Richiesta Divisa Consegnata</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Creata il:
        </p>
        <h4 class="mb-1">
          {{ parsedate(uniformData.created_at) }}
        </h4>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Approvata il:
        </p>
        <h4 class="mb-1">
          {{ parsedate(uniformData.approved_at) }}
        </h4>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Consegnata il:
        </p>
        <h4 class="mb-1">
          {{ parsedate(uniformData.delivered_at) }}
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="uniformData.items && uniformData.items.length > 0"
        class="mb-2"
        cols="12"
      >
        <p class="mb-1">
          Capi Richiesti:
        </p>
        <b-row
          v-for="item in uniformData.items"
          :key="item.id"
          class="mb-2"
          align-v="center"
        >
          <b-col
            class="d-flex align-items-center"
            style="font-size: 1.25rem;"
          >
            <span
              class="mr-2"
              style="font-size: 1.5rem;"
            >•</span>
            <b-badge
              variant="light-info"
              class="mr-2"
            >
              {{ item.uniform.name }}
            </b-badge>
            <b-badge
              variant="light-success"
            >
              x{{ item.quantity }}
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
  },
  props: {
    uniformData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatarText,
      selectedUniform: null,
    }
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL
    },
  },
  methods: {
    parsedate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'info'
      if (role === 'hr') return 'warning'
      if (role === 'reception_hub') return 'warning'
      if (role === 'reception_colle') return 'warning'
      if (role === 'accountant') return 'warning'
      if (role === 'admin') return 'danger'
      return 'danger'
    },
    goBack() {
      this.$router.go(-1)
    },
    deliverUniform(id) {
      store
        .dispatch('app-uniforms/deliverUniforms', { id })
        .then(() => {
          this.selectedUniform = null
          this.$router.replace({ name: 'apps-uniformsreception-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Consegna Divisa #${id} conseguita con successo`,
                icon: 'ShoppingBagIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
