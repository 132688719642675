<template>
  <div>
    <b-alert
      variant="danger"
      :show="uniformData === undefined"
    >
      <h4 class="alert-heading">
        Errore dati Richiesta Divisa</h4>
      <div class="alert-body">
        Nessuna Richiesta di Divisa trovata con questo ID. Controlla la
        <b-link
          class="alert-link"
          :to="{ name: 'apps-uniformsreception-list' }"
        > Lista delle Richieste </b-link>
        per altre.
      </div>
    </b-alert>

    <template v-if="uniformData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <manager-uniforms-view-uniform-info-card :uniform-data="uniformData" />
          <manager-uniforms-view-latest-uniform-info-card
            v-if="latestUniformData"
            :uniform-data="latestUniformData"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BAlert,
  BLink,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import ManagerUniformsViewUniformInfoCard from './ManagerUniformsViewUniformInfoCard.vue'
import ManagerUniformsViewLatestUniformInfoCard from './ManagerUniformsViewLatestUniformInfoCard.vue'
import manageruniformsStoreModule from '../manageruniformsStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    ManagerUniformsViewUniformInfoCard,
    ManagerUniformsViewLatestUniformInfoCard,
  },
  setup() {
    const uniformData = ref(null)
    const latestUniformData = ref(null)
    const UNIFORMS_APP_STORE_MODULE_NAME = 'app-manageruniforms'

    // Register module
    if (!store.hasModule(UNIFORMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(UNIFORMS_APP_STORE_MODULE_NAME, manageruniformsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(UNIFORMS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(UNIFORMS_APP_STORE_MODULE_NAME)
      }
    })

    store
      .dispatch('app-manageruniforms/fetchUniform', { id: router.currentRoute.params.id })
      // eslint-disable-next-line consistent-return
      .then(response => {
        uniformData.value = response.data
        if (uniformData.value && uniformData.value.user && uniformData.value.user.id) {
          return store.dispatch('app-manageruniforms/fetchLatestUniform', { userId: uniformData.value.user.id })
        }
      })
      .then(response => {
        if (response) {
          latestUniformData.value = response.data
        }
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    return {
      uniformData,
      latestUniformData,
    }
  },
}
</script>

<style></style>
