<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-row>
      <b-col
        class="mb-4 d-flex justify-content-center align-items-center"
        cols="12"
      >
        <feather-icon
          icon="ShoppingBagIcon"
          size="25"
          class="mr-2"
        />
        <h1>Informazioni Richiesta Divisa</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          ID Richiesta:
        </p>
        <h3 class="mb-1">
          #{{ localUniformData.id }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Creata il:
        </p>
        <h4 class="mb-1">
          {{ parsedate(localUniformData.created_at) }}
        </h4>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Utente:
        </p>
        <div class="text-nowrap">
          <div class="d-flex align-items-center">
            <b-avatar
              size="25"
              :src="localUniformData.user.avatar_file ? mediaUrl + localUniformData.user.avatar_file : ''"
              :text="avatarText(localUniformData.user.display_name)"
              :variant="`light-${resolveUserRoleVariant(localUniformData.user.role_code)}`"
              class="mr-1"
            />
            <b-link>
              #{{ localUniformData.user.anagrafico_id }} - {{ localUniformData.user.display_name }}
            </b-link>
          </div>
        </div>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Reparto:
        </p>
        <div class="text-nowrap">
          <div class="d-flex align-items-center">
            <b-link>
              #{{ localUniformData.department.id }} - {{ localUniformData.department.name }}
            </b-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="localUniformData.items && localUniformData.items.length > 0"
        class="mb-2"
        cols="12"
      >
        <p class="mb-1">
          Capi Richiesti:
          <b-button
            variant="outline-warning"
            class="ml-1"
            @click="openEditModal()"
          >
            Modifica Capi Richiesti
          </b-button>
        </p>
        <b-row
          v-for="item in localUniformData.items"
          :key="item.id"
          class="mb-2"
          align-v="center"
        >
          <b-col
            class="d-flex align-items-center"
            style="font-size: 1.25rem;"
          >
            <span
              class="mr-2"
              style="font-size: 1.5rem;"
            >•</span>
            <b-badge
              variant="light-info"
              class="mr-2"
            >
              {{ item.uniform.name }}
            </b-badge>
            <b-badge
              variant="light-success"
            >
              x{{ item.quantity }}
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="text-right mt-2"
        cols="12"
      >
        <b-button
          variant="outline-danger"
          class="ml-1"
          @click="
            $bvModal.show('reject-modal')
            selectedUniform = localUniformData
          "
        >
          Rifiuta Richiesta
        </b-button>
        <b-button
          variant="outline-success"
          class="ml-1"
          @click="
            $bvModal.show('approve-modal')
            selectedUniform = localUniformData
          "
        >
          Approva Richiesta
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="approve-modal"
      title="Approva Richiesta Divisa"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Approva Richiesta Divisa
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler approvare la richiesta e tutti i capi selezionati?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            approveUniform(selectedUniform.id)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="reject-modal"
      title="Rifiuta Richiesta Divisa"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Rifiuta Richiesta Divisa
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler rifiutare la richiesta e tutti i capi selezionati?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            rejectUniform(selectedUniform.id)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="edit-modal"
      title="Modifica Capi Richiesti"
      footer-class="d-flex justify-content-end"
      body-class="pt-1 px-1 pb-1"
      size="lg"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Modifica Capi Richiesti
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <b-table
        :items="assignments"
        :fields="fields"
        small
        responsive
        striped
        hover
        head-variant="light"
      >
        <template #cell(uniformName)="data">
          <span class="font-weight-bold text-uppercase">
            {{ data.item.uniformName }}
          </span>
          <b-badge
            v-if="data.item.groupId"
            variant="light-info"
            class="ml-1"
          >
            {{ data.item.groupId }}
          </b-badge>
        </template>

        <template #cell(maxQuantity)="data">
          <span class="badge badge-light-success">{{ data.item.maxQuantity }}</span>
        </template>

        <template #cell(selectedQuantity)="data">
          <b-form-select
            v-model="selectedQuantities[data.item.id]"
            :options="getQuantityOptionsDynamic(data.item)"
            class="w-75"
            @change="onDynamicQuantityChange(data.item)"
          />
        </template>
      </b-table>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            saveChanges()
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BLink, BModal, BAvatar, BBadge, BTable, BFormSelect,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BLink,
    BModal,
    BAvatar,
    BBadge,
    BTable,
    BFormSelect,
  },
  props: {
    uniformData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localUniformData: { ...this.uniformData },
      avatarText,
      selectedUniform: null,
      assignments: [],
      selectedQuantities: {},
      fields: [
        { key: 'uniformName', label: 'Capo' },
        { key: 'maxQuantity', label: 'Max' },
        { key: 'selectedQuantity', label: 'Quantità' },
      ],
    }
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL
    },
  },
  watch: {
    uniformData(newVal) {
      this.localUniformData = { ...newVal }
    },
  },
  methods: {
    getQuantityOptionsDynamic(item) {
      const { groupId } = item
      const groupMax = item.maxQuantity

      let sumOfOthers = 0
      this.assignments
        .filter(a => a.groupId === groupId && a.id !== item.id)
        .forEach(a => {
          sumOfOthers += parseInt(this.selectedQuantities[a.id] || 0, 10)
        })

      const remaining = groupMax - sumOfOthers
      const options = []
      for (let i = 0; i <= remaining; i += 1) {
        options.push({ value: i, text: i.toString() })
      }
      return options
    },
    onDynamicQuantityChange(item) {
      const { groupId } = item
      const groupMax = item.maxQuantity
      let sum = 0
      this.assignments
        .filter(a => a.groupId === groupId)
        .forEach(a => {
          sum += parseInt(this.selectedQuantities[a.id] || 0, 10)
        })
      if (sum === groupMax) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Hai raggiunto il limite di ${groupMax} capo/i per questo gruppo`,
            icon: 'ShoppingBagIcon',
            variant: 'info',
          },
        })
      }
      this.assignments = [...this.assignments]
    },
    getQuantityOptions(max) {
      const options = []
      options.push({ value: 0, text: '0' })
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= max; i++) {
        options.push({ value: i, text: i.toString() })
      }
      return options
    },
    parsedate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'info'
      if (role === 'hr') return 'warning'
      if (role === 'reception_hub') return 'warning'
      if (role === 'reception_colle') return 'warning'
      if (role === 'accountant') return 'warning'
      if (role === 'admin') return 'danger'
      return 'danger'
    },
    goBack() {
      this.$router.go(-1)
    },
    openEditModal() {
      const departmentId = this.localUniformData.department.id
      const { gender } = this.localUniformData.user

      store.dispatch('app-manageruniforms/fetchUniformAssignments', {
        departmentId,
        gender,
      })
        .then(response => {
          this.assignments = response.data[0] || []
          this.selectedQuantities = {}
          if (this.localUniformData.items) {
            this.localUniformData.items.forEach(item => {
              const foundAssignment = this.assignments.find(a => a.uniformId === item.uniform.id)
              if (foundAssignment) {
                this.selectedQuantities[foundAssignment.id] = item.quantity
              }
            })
          }
          this.$bvModal.show('edit-modal')
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    approveUniform(id) {
      store
        .dispatch('app-manageruniforms/approveUniforms', { id })
        .then(() => {
          this.selectedUniform = null
          this.$router.replace({ name: 'apps-manageruniforms-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Richiesta Divisa #${id} approvata con successo`,
                icon: 'ShoppingBagIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    rejectUniform(id) {
      store
        .dispatch('app-manageruniforms/rejectUniforms', { id })
        .then(() => {
          this.selectedUniform = null
          this.$router.replace({ name: 'apps-manageruniforms-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Richiesta Divisa #${id} rifiutata con successo`,
                icon: 'ShoppingBagIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    saveChanges() {
      const itemsToUpdate = []
      Object.keys(this.selectedQuantities).forEach(assignmentId => {
        const quantity = parseInt(this.selectedQuantities[assignmentId], 10) || 0
        if (quantity > 0) {
          const assignment = this.assignments.find(a => a.id === parseInt(assignmentId, 10))
          if (assignment) {
            itemsToUpdate.push({
              uniformId: assignment.uniformId,
              quantity,
            })
          }
        }
      })

      store.dispatch('app-manageruniforms/updateUniform', {
        id: this.localUniformData.id,
        formData: { items: itemsToUpdate },
      })
        .then(() => {
          this.$bvModal.hide('edit-modal')
          return store.dispatch('app-manageruniforms/fetchUniform', { id: this.localUniformData.id })
        })
        .then(response => {
          this.localUniformData = response.data
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Richiesta Divisa #${this.localUniformData.id} aggiornata con successo`,
              icon: 'ShoppingBagIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
